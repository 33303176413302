import request from '@/utils/request'


// 查询CGM设备列表
export function listCgmDevice(query) {
  return request({
    url: '/cgmDevice/cgmDevice/list',
    method: 'get',
    params: query
  })
}

// 查询CGM设备分页
export function pageCgmDevice(query) {
  return request({
    url: '/cgmDevice/cgmDevice/page',
    method: 'get',
    params: query
  })
}

// 查询CGM设备详细
export function getCgmDevice(data) {
  return request({
    url: '/cgmDevice/cgmDevice/detail',
    method: 'get',
    params: data
  })
}

// 新增CGM设备
export function addCgmDevice(data) {
  return request({
    url: '/cgmDevice/cgmDevice/add',
    method: 'post',
    data: data
  })
}

// 修改CGM设备
export function updateCgmDevice(data) {
  return request({
    url: '/cgmDevice/cgmDevice/edit',
    method: 'post',
    data: data
  })
}

// 删除CGM设备
export function delCgmDevice(data) {
  return request({
    url: '/cgmDevice/cgmDevice/delete',
    method: 'post',
    data: data
  })
}
